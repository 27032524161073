import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"

import "../../styles/pages/sunglasses.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlassesHeading from "../../components/GlassesHeading"
import Breadcrumbs from "../../components/Breadcrumbs"
import GlassesFilter from "../../components/GlassesFilter"
import GlassesList from "../../components/GlassesList"
import ProductBanner from "../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../services/hooks"
import CartContext from "../../context/cartContext"

const Sunglasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString, sunglassesAvailable }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern") &&
        sunglassesAvailable
      )
    }
  )

  const right_side_text =
    "Our on-trend and durable shades are designed with the modern man in mind, and offer a sleek and sophisticated look with a wide range of frame style and lens options to choose from."
  const list_items = [
    "Quality first. High-quality men’s sunglasses at prices you can’t believe; anti-reflective, anti-glare, anti-scratch, hydrophobic and oleophobic coatings come standard on every pair, keeping you protected from the sun’s harmful rays.",
    "Comfort Matters. With durable barrel hinges and padded temple tips for extra comfy cush.",
    "Personalized just for you. Ease your eyes—add reader magnification to all of our sunglasses.",
    "Made with care. Every pair of men’s sunglasses is made to order and hand-assembled by one of our team members.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: ["male"],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    // navigateWithFilters(location.pathname, debouncedFilter)
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("green")

    return () => {
      setHeaderColor("black")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'sunglasses',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Sunglasses-Mens`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [{
    "@url": "https://hubblecontacts.com/sunglasses/mens-sunglasses",
    "@type": "webpage",
    "name": "Mens Sunglasses: Prescription Sunglasses Online | Hubble",
    "description": "Shop our wide range of mens sunglasses online to find your next pair of shades. Find everything from prescription sunglasses to polarized sunglasses for men available in a variety of styles.",
    "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
    }
  }]

  return (
    <Layout>
      <SEO
        title="Mens Sunglasses: Prescription Sunglasses Online"
        description="Shop our wide range of mens sunglasses online to find your next pair of shades. Find everything from prescription sunglasses to polarized sunglasses for men available in a variety of styles."
        schemaMarkup={schemaMarkup} />
      <div className="sunglasses-content">
        <GlassesHeading
          color="green"
          title="Men's Sunglasses"
          description="High-quality, stylish sunglasses for men with polarized lenses that block 99%
                        of UVA/UVB rays and eliminate daytime glare. But you know what really makes
                        them shine? You can add your prescription to every single pair."
        />

        <div className="glasses-container">
          <div className="grid-section">
            <Breadcrumbs
              color="green"
              links={[{ to: "/", label: "Homepage" }, { label: "Sunglasses" }]}
            />
            <GlassesFilter
              type="sunglasses"
              glassesLength={sortedGlasses.length}
              filter={filter}
              setFilter={setFilter}
            />
            <GlassesList
              type="sunglasses"
              glasses={sortedGlasses}
              color={filter.color}
              gender={filter.gender[0]}
            />
            <ProductBanner
              title={"Sunglasses for men"}
              right_side_text={right_side_text}
              list_items={list_items}
              optional_image="Hubble-Sunglasses-PCP-Inset-Lifestyle-Image.jpg"
              color="green"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sunglasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        sunglassesMaleHeaderImage1
        sunglassesFemaleHeaderImage1
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
